import React from 'react';
import { SiSemanticrelease } from 'react-icons/si'
const Releases = () => {
    return (
        <div className='container mx-auto flex flex-col space-y-3 px-10 h-fit py-10'>
            <div className='text-gray-300 text-2xl flex items-center md:text-4xl font-extrabold px-5'>
                <SiSemanticrelease />
                <h1 className='ml-2'>Lastest Releases</h1>
            </div>
            <div className='px-5 py-2 md:px-0'>
                <div className='container bg-white mx-auto h-[1px]'></div>
            </div>
            <div className='grid gap-2 lg:grid lg:grid-cols-2 lg:gap-3'>
                <a href="https://www.beatport.com/release/sera-el-flamenco/4347022" target='_blank' rel='noreferrer'>
                    <div className='md:flex h-fit p-4 lg:text-base xl:text-xl font-bold bg-black bg-opacity-90 text-gray-300 hover:border-[#C21010] hover:border '>
                        <img className='md:w-40' src="https://geo-media.beatport.com/image_size/1400x1400/9fb1455c-ac96-4c92-94b2-84df5d4c4328.jpg" alt="new released" />
                        <div className='pt-3 md:pt-0 md:pl-6 space-y-6 h-fit'>
                            <div>GERMAN BRIGANTE</div>
                            <div>SERA EL FLAMENCO</div>
                            <div className='text-[#C21010] animate-pulse'>LAST RELEASE.</div>
                        </div>
                    </div>
                </a>
                <a href="https://www.beatport.com/release/cultures/4286095" target='_blank' rel='noreferrer'>
                    <div className='md:flex h-fit p-4 lg:text-xl font-bold bg-black bg-opacity-90 text-gray-300 hover:border-[#C21010] hover:border'>
                        <img className='md:w-40' src="https://geo-media.beatport.com/image_size/1400x1400/4dac7641-0587-444a-acdc-57bd7b6252aa.jpg" alt="new released" />
                        <div className='pt-3 md:pt-0 md:pl-6 space-y-6'>
                            <div>GERMAN BRIGANTE - ALEXANDER ROBLEDO</div>
                            <div>CULTURES</div>
                            <div className='text-[#C21010] animate-pulse'>LAST RELEASE.</div>
                        </div>
                    </div>
                </a>


            </div>
        </div>
    );
};

export default Releases;